import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import { Container, BaseButton } from "../components/styledComponents"
import { BigMobileFont } from "../components/typography"
import LastCTASection from "../components/lastCTASection"
import ImageTopSection from "../components/imageTopSection"
import ProjectsWithCategories from "../components/projectsWithCategories"
import FlowMapImage from "../components/flowMapImage"
import Popup from "../components/popup"

const HeroSection = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  min-height: calc(100vh - 120px);
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
  }
`

const HeroImageWrapper = styled.div`
  margin: 0 40px 0 0;
  width: 40%;
  @media (max-width: 768px) {
    width: 80%;
    margin: 0 0 1rem 0;
  }
`

const HeroHeader = styled.h1`
  margin-bottom: 80px;
  @media (max-width: 768px) {
    margin-bottom: 0.75rem;
  }
`

const StyledButton = styled(BaseButton)`
  position: absolute;
  right: 0;
  bottom: 80px;
  @media (max-width: 768px) {
    position: static;
    margin-bottom: 80px;
  }
`

const Portfolio = ({ data }) => {
  const {
    firstSectionImage,
    firstSectionTitle,
    firstSectionButtonText,
    firstSectionButtonUrl,
    lastCTASectionButtonText,
    lastCTASectionButtonUrl,
    lastCTASectionText,
    lastCTASectionTitle,
    teamSectionText,
    teamSectionTitle,
    teamSectionImage,
  } = data.wordpressPage.cmb2.metabox_portfolio
  return (
    <Layout>
      <SEO
        meta={data.wordpressPage.yoast_meta}
        title={data.wordpressPage.title}
        path="/portfolio"
      />
      <Container>
        <HeroSection>
          <HeroImageWrapper>
            <FlowMapImage
              imagePublicURL={firstSectionImage.localFile.publicURL}
              size={{ width: 118, height: 150 }}
            />
          </HeroImageWrapper>
          <HeroHeader>
            <BigMobileFont desktopFontSize="3.5rem">
              {firstSectionTitle}
            </BigMobileFont>
          </HeroHeader>
          {firstSectionButtonText && (
            <StyledButton to={firstSectionButtonUrl}>
              {firstSectionButtonText}
            </StyledButton>
          )}
        </HeroSection>
      </Container>
      <ProjectsWithCategories
        projects={data.allWordpressWpProject.nodes}
        projectsTaxonomy={data.allWordpressWpProjectTaxonomy.nodes}
      />
      <ImageTopSection
        title={teamSectionTitle}
        text={teamSectionText}
        localFile={teamSectionImage.localFile}
      />
      <LastCTASection
        title={lastCTASectionTitle}
        text={lastCTASectionText}
        buttonText={lastCTASectionButtonText}
        buttonUrl={lastCTASectionButtonUrl}
      />
      <Popup />
    </Layout>
  )
}
export const query = graphql`
  query PortfolioPageQuery {
    wordpressPage(template: { eq: "page-templates/page-portfolio.php" }) {
      title
      yoast_meta {
        content {
          source_url
        }
        normalized_content
        name
        property
      }
      cmb2 {
        metabox_portfolio {
          firstSectionImage {
            localFile {
              publicURL
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          firstSectionTitle
          firstSectionButtonText
          firstSectionButtonUrl
          lastCTASectionButtonText
          lastCTASectionButtonUrl
          lastCTASectionText
          lastCTASectionTitle
          teamSectionText
          teamSectionTitle
          teamSectionImage {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    allWordpressWpProjectTaxonomy {
      nodes {
        name
        wordpress_id
      }
    }
    allWordpressWpProject {
      nodes {
        cmb2 {
          metabox_post_project {
            text
            title
            buttonText
            buttonUrl
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
        project_taxonomy
      }
    }
  }
`

export default Portfolio
