import React from "react"
import styled from "styled-components"
import BaseImg from "./baseImg"
import { Container } from "./styledComponents"
import { SmallMobileFont } from "./typography"

const ContentBox = styled.div`
  position: relative;
  z-index: 2;
  margin: -130px auto 0;
  padding: 2.125rem;
  background-color: #fff;
  box-shadow: 0 3px 6px #00000029;
  max-width: 60%;
  @media (max-width: 1195px) {
    max-width: 90%;
    margin: -10% auto 0;
  }
`

const ImageTopSection = ({ title, text, localFile }) => {
  return (
    <Container size="medium">
      <BaseImg localFile={localFile} />
      <ContentBox>
        <h2 style={{ marginBottom: "0.75rem" }}>
          <SmallMobileFont>{title}</SmallMobileFont>
        </h2>
        <p
          dangerouslySetInnerHTML={{ __html: text }}
          style={{ marginBottom: "0", lineHeight: "1.625" }}
        ></p>
      </ContentBox>
    </Container>
  )
}

export default ImageTopSection
